<template>
  <video ref="VideoTrack" class="video-track" :srcObject.prop="track || null" :style="style" />
</template>

<style lang="scss" scoped>
::v-deep {
  .video-track {
    transform: scaleX(-1);

    &:-webkit-full-scren {
      transform: scaleX(-1);
    }
  }
}
</style>

<script>
export default {
  name: 'VideoTrack',
  props: {
    track: {
      type: Object
    },
    isLocal: {
      type: Boolean
    },
    priority: {
      type: String, // "high" | "low" | "standard"
      default: 'standard'
    },
    muted: Boolean
  },
  data () {
    return {
      mediaStreamTrack: null,
      dimensions: {},
      isPortrait: true,
      style: {}
    }
  },
  beforeMount () {
    this.mediaStreamTrack = this.track?.mediaStreamTrack
    this.dimensions = this.track?.dimensions
    this.isPortrait =
      (this.dimensions?.height || 0) > (this.dimensions?.width || 0)
  },
  mounted () {
    this.setStyleVideoTrack(this.track)
    this.setDOMVideoTrack(this.track)
  },
  watch: {
    track: {
      handler (data) {
        this.setStyleVideoTrack(data)
        this.setStartedOnTrack(data)
        this.setDimensionsChangeOnTrack(data)
      },
      deep: true
    },
    changeData: {
      handler (data) {
        this.setDOMVideoTrack(data[0])
      },
      deep: true
    }
  },
  computed: {
    changeData () {
      const { track, priority } = this
      return [track, priority]
    }
  },
  methods: {
    setStyleVideoTrack (data) {
      const trackData = data || this.track
      if (!trackData) return
      const isFrontFacing =
        this.mediaStreamTrack?.getSettings().facingMode !== 'environment'
      this.style = {
        transform: this.isLocal && isFrontFacing ? 'scaleX(-1)' : '',
        objectFit:
          trackData?.name?.includes('screen') || this.isPortrait
            ? 'contain'
            : 'cover'
      }
    },
    handleStarted (data) {
      const trackData = data || this.track
      if (!trackData) return
      this.mediaStreamTrack = trackData?.mediaStreamTrack
    },
    setStartedOnTrack (data) {
      const trackData = data || this.track
      if (!trackData) return
      trackData.on('started', this.handleStarted)
    },
    setStartedOffTrack () {
      if (!this.track) return
      this.track.off('started', this.handleStarted)
    },
    handleDimensionsChanged (newTrack) {
      this.dimensions = {
        width: newTrack.dimensions.width,
        height: newTrack.dimensions.height
      }
    },
    setDimensionsChangeOnTrack (data) {
      const trackData = data || this.track
      if (!trackData) return
      trackData.on('dimensionsChanged', this.handleDimensionsChanged)
    },
    setDimensionsChangeOffTrack (data) {
      const trackData = data || this.track
      if (!trackData) return
      trackData.off('dimensionsChanged', this.handleDimensionsChanged)
    },
    setDOMVideoTrack (data) {
      const trackData = data || this.track
      if (!this.$refs.VideoTrack) return

      const video = this.$refs.VideoTrack

      video.muted = this.muted || true
      video.autoplay = true
      video.playsinline = true
      video.controls = false
      // video.disablePictureInPicture = true
      video.setAttribute('data-video-track-name', trackData?.id)

      if (trackData?.setPriority && this.priority) {
        trackData.setPriority(this.priority)
      }

      trackData && this.track.attach(video)
    }
  },
  destroyed () {
    this.setStartedOffTrack()
    this.setDimensionsChangeOffTrack()

    if (this.$refs.VideoTrack && this.track) {
      this.track.detach(this.$refs.VideoTrack)
      this.$refs.VideoTrack.remove()
      this.$refs.VideoTrack.srcObject = null

      if (this.track?.setPriority && this.priority) {
        this.track.setPriority(null)
      }
    }
  }
}
</script>
