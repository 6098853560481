<template>
  <div class="call-container">
    <!-- header -->
    <div class="twr-header" v-if="roomData">
      <MemberCounter />
    </div>

    <!-- body -->
    <div class="row stream-wraper mx-0" v-if="roomData">
      <ParticipantAudioTracks />
      <div class :class="isVertical ? 'col-12' : 'col-9'">
        <div id="zoomed-wraper" :class="isVertical ? 'vertical' : 'horizontal'">
          <MainParticipant :room="roomData" />
        </div>
      </div>
      <div class id="media-container" :class="isVertical ? 'vertical col-12' : 'horizontal col-3'"></div>
    </div>

    <div class="empty-wraper" v-if="!roomData && isLoading">
      <img src="../../../public/assets/images/gif/Loading.gif" width="212" height="212" alt />
    </div>

    <!-- footer -->
    <div></div>

    <div id="audio-wraper" class="d-none">
      <audio ref="audioJoin" id="signal-end" preload="auto">
        <source src="../../../public/assets/audio/Join.mp3" type="audio/mp3" />
      </audio>
      <audio ref="audioLeave" id="signal-end" preload="auto">
        <source src="../../../public/assets/audio/Out.mp3" type="audio/mp3" />
      </audio>
    </div>
  </div>
</template>

<script>
import MemberCounter from './MemberCounter.vue'
import MainParticipant from './MainParticipant.vue'
import ParticipantAudioTracks from './Tracks/ParticipantAudioTracks.vue'

export default {
  name: 'HoDoConferenceX',
  components: { MainParticipant, MemberCounter, ParticipantAudioTracks },
  inject: ['videoProvide'],
  data () {
    return {
      token: '',
      member_joined: [],
      member_joined_count: 0,
      is_support_browser: false,
      doctor_id: null,
      total_time: 0,
      start_count: null,
      isVertical: false,
      room: this.videoProvide.room || null,
      roomData: null,
      isLoading: this.videoProvide.isConnecting || true
    }
  },
  computed: {},
  watch: {
    videoProvide: {
      handler (data) {
        this.room = data.room || null
        this.isLoading = data.isConnecting || true
      },
      deep: true,
      immediate: true
    },
    room: {
      handler (r) {
        this.roomData = r
        this.isLoading = false

        if (!r) {
          this.member_joined_count = 0
        } else {
          this.getMemberJoined(r)
        }
      },
      deep: true
    },
    started_seconds (s) {}
  },
  created () {
    const self = this
    window.addEventListener('unload', function (e) {
      self.disconnect(e)
    })
    window.addEventListener('resize', this.calculationRatio)
  },
  mounted () {
    this.doctor_id = this.$user?.doctor?.id
    this.calculationRatio()
  },
  methods: {
    calculationRatio () {
      const w = window.screen.width
      const h = window.screen.height
      this.isVertical = w / h < 3 / 4
    },
    getMemberJoined (data) {
      const roomInfo = data || this.room || this.roomData
      this.member_joined_count = roomInfo?.participants?.size + 1 || 1
    },
    isFirefox () {
      var mediaSourceSupport = !!navigator.mediaDevices.getSupportedConstraints()
        .mediaSource
      var matchData = navigator.userAgent.match('Firefox')
      var firefoxVersion = 0
      if (matchData && matchData[1]) {
        firefoxVersion = parseInt(matchData[1], 10)
      }
      return mediaSourceSupport && firefoxVersion >= 52
    },
    isChrome () {
      return 'chrome' in window
    },
    canScreenShare () {
      return this.isFirefox() || this.isChrome()
    },
    async getUserScreen () {
      if (!this.canScreenShare()) {
        alert('Trình duyệt của bạn không hỗ trợ tính năng này!')
        return
      }
      if (this.isChrome()) {
        return navigator.mediaDevices
          .getDisplayMedia()
          .then(stream => {
            return stream
          })
          .catch(e => {
            console.log(e)
          })
      } else if (this.isFirefox()) {
        return navigator.mediaDevices.getUserMedia({
          video: {
            mediaSource: 'screen'
          }
        })
      }
    },
    startTimeCount () {
      this.total_time = this.started_seconds
      setTimeout(() => {
        this.start_count = setInterval(this.setTime, 1000)
      }, 25)
    },
    stopTimeCount () {
      clearInterval(this.start_count)
    },
    setTime () {
      this.total_time++
    },
    fancyTimeFormat (duration) {
      // Hours, minutes and seconds
      var hrs = ~~(duration / 3600)
      var mins = ~~((duration % 3600) / 60)
      var secs = ~~duration % 60

      // Output like "1:01" or "4:03:59" or "123:03:59"
      var ret = ''

      if (hrs > 0) {
        ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
      }

      ret += '' + mins + ':' + (secs < 10 ? '0' : '')
      ret += '' + secs
      return ret
    },
    showInfo () {
      this.$emit('showInfo')
    },
    showFunctions () {
      this.$emit('showFunctions')
    },
    roomDisconnect () {
      const self = this
      self.stopTimeCount()
      if (!self.room) return
      self.room.on('disconnected', room => {})
      self.room.disconnect()
    }
  },
  beforeDestroy () {
    this.roomDisconnect()
  },
  destroyed () {
    window.addEventListener('resize', this.calculationRatio)
  }
}
</script>

<style lang="css" scoped>
.room-name {
  justify-content: flex-start;
  margin-left: 12px;
  max-width: 100%;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  align-items: center;
  display: flex;
  flex: 1 1 25%;
}
.disease-in-process-name {
  width: calc(100vw - 400px);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.bottom-action {
  align-items: center;
  display: flex;
  flex: 1 1 25%;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
}
.info-btn-group {
  align-items: center;
  display: flex;
  flex: 1 1 25%;
  justify-content: flex-end;
  margin-right: 18px;
}
.b-action {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: 0;
  margin-right: 1.25vw;
  margin-bottom: 3px;
}
.b-action svg {
  max-width: 100%;
}
.action-active {
  background-color: #0d0e11;
}
.action-inactive {
  background-color: #ffffff;
}
.b-leave {
  background-color: #ff3636;
}
.call-container {
  position: relative;
}
.box-shadow {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
button:disabled {
  cursor: not-allowed;
}
.btn-open-tab {
  width: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 8px;
  margin-right: 5px;
}
.btn-header {
  width: 142px;
}
.footer-wraper {
  align-items: center;
  bottom: 8px;
  box-sizing: border-box;
  color: #202124;
  display: flex;
  margin-bottom: 0;
  position: fixed;
  text-align: center;
  white-space: nowrap;
  width: 100vw;
  z-index: 1051;
}
.bg-3C4043 {
  background-color: #3c4043;
}
.empty-wraper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-open-info {
  width: 40px;
  height: 40px;
  background-color: #3c4043;
  border: 0;
}
.twr-header {
  position: relative;
  height: 16px;
}
.member-counter {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  right: 0;
}
</style>
