<template>
  <div ref="AudioTrack" class="hidden"></div>
</template>

<script>
export default {
  name: 'AudioTrack',
  props: {
    track: {
      type: Object
      // required: true
    }
  },
  mounted () {
    this.setDOMAudioTrack()
  },
  watch: {
    track: {
      handler (data) {
        this.setDOMAudioTrack(data)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    setDOMAudioTrack (data) {
      if (!this.$refs.AudioTrack) return

      const audioData = data || this.track
      const el = this.$refs.AudioTrack
      const audio = document.createElement('audio')
      audio.setAttribute('data-audio-track-name', audioData?.id)

      audioData && el.appendChild(audioData.attach(audio))
    },
    detachAudioTrack () {
      this.track &&
        this.track.detach().forEach(el => {
          el.remove()
          el.srcObject = null
        })
    }
  },
  destroyed () {
    this.detachAudioTrack()
  }
}
</script>

<style>
</style>
