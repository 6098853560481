<template>
  <button
    :disabled="!hasVideoInputDevices || disabled"
    @click="toggleVideoEnabled"
  >{{ !hasVideoInputDevices ? 'No Video' : isVideoEnabled ? 'Stop Video' : 'Start Video' }}</button>
</template>

<script>
import { useDevices } from '../../../mixins/VideoCore/useDevices'
import { useLocalDevicesToggle } from '../../../mixins/VideoCore/useLocalDevicesToggle'

export default {
  name: 'ToggleVideoButton',
  props: {
    disabled: {
      type: Boolean
    }
  },
  mixins: [useDevices, useLocalDevicesToggle],
  data () {
    return {}
  },
  mounted () {},
  watch: {},
  computed: {
    hasVideoInputDevices () {
      const { hasVideoInputDevices } = this.deviceInfo
      return hasVideoInputDevices
    }
  }
}
</script>

<style>
</style>
