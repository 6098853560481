<template>
  <div v-if="Boolean(room)" id="main-track" class="text-white h-100 position-relative">
    <ParticipantTracks
      videoOnly
      :participant="mainParticipant"
      :videoPriority="videoPriority"
      :isLocalParticipant="isLocalParticipant"
    />
  </div>
</template>

<style lang="scss">
#main-track {
  &::before {
    content: "";
    display: block;
    height: 100%;
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>

<script>
import { useDominantSpeaker } from '../../mixins/VideoCore/useDominantSpeaker'
import { useParticipants } from '../../mixins/VideoCore/useParticipants'
import ParticipantTracks from './Tracks/ParticipantTracks.vue'

export default {
  name: 'MainParticipant',
  mixins: [useParticipants, useDominantSpeaker('includeNull')],
  components: { ParticipantTracks },
  data () {
    return {
      includeNull: true,
      localParticipant: null
    }
  },
  mounted () {},
  watch: {
    room: {
      handler (data) {
        this.localParticipant = data?.localParticipant
      },
      deep: true,
      immediate: true
    },
    localParticipant: {
      handler (data) {
        console.log(data)
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    mainParticipant () {
      return (
        this.dominantSpeaker || this.participants?.[0] || this.localParticipant
      )
    },
    isLocalParticipant () {
      return this.mainParticipant === this.localParticipant
    },
    videoPriority () {
      return this.mainParticipant !== this.localParticipant ? 'high' : null
    }
  }
}
</script>

<style>
</style>
