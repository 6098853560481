<template>
  <div v-if="participants.length">
    <ParticipantAudio
      v-for="(participant) in participants"
      :key="participant.sid"
      :participant="participant"
    />
  </div>
</template>

<script>
import { useParticipants } from '../../../mixins/VideoCore/useParticipants'
import ParticipantAudio from './ParticipantAudio.vue'

export default {
  name: 'ParticipantAudioTracks',
  components: { ParticipantAudio },
  mixins: [useParticipants]
}
</script>

<style>
</style>
