<template>
  <div>
    <Publication
      v-for="(publication) in filteredPublications"
      :key="publication.kind"
      :publication="publication"
      :participant="participant"
      :isLocalParticipant="isLocalParticipant"
      :videoOnly="videoOnly"
      :videoPriority="videoPriority"
    />
  </div>
</template>

<script>
import { usePublications } from '../../../mixins/VideoCore/usePublications'
import Publication from './Publication.vue'

export default {
  name: 'ParticipantTracks',
  props: {
    participant: Object,
    videoOnly: Boolean,
    enableScreenShare: Boolean,
    videoPriority: String || null,
    isLocalParticipant: Boolean
  },
  mixins: [usePublications('participant')],
  components: { Publication },
  data () {
    return {
      filteredPublications: null
    }
  },
  beforeMount () {
    this.setFilteredPublications()
  },
  watch: {
    publications: {
      handler (data) {
        this.setFilteredPublications(data)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    setFilteredPublications (data) {
      const publicationsData = data || this.publications
      if (
        this.enableScreenShare &&
        publicationsData.some(p => p?.trackName?.includes('screen'))
      ) {
        this.filteredPublications = publicationsData.filter(
          p => p?.trackName?.includes('screen') || p.kind !== 'video'
        )
      } else {
        this.filteredPublications = publicationsData.filter(
          p => !p?.trackName?.includes('screen')
        )
      }
    }
  }
}
</script>

<style>
</style>
