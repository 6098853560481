<template>
  <button
    :disabled="!hasAudioTrack || disabled"
    @click="toggleAudioEnabled"
  >{{ !hasAudioTrack ? 'No Audio' : isEnabled ? 'Stop Audio' : 'Start Audio' }}</button>
</template>

<script>
import { useLocalDevicesToggle } from '../../../mixins/VideoCore/useLocalDevicesToggle'

export default {
  name: 'ToggleAudioButton',
  props: {
    disabled: {
      type: Boolean
    }
  },
  mixins: [useLocalDevicesToggle],
  // inject: ['localTracks'],
  data () {
    return {}
  },
  mounted () {},
  watch: {
    localTracks: {
      handler (data) {
        // console.log(data)
      },
      deep: true
    }
  },
  computed: {
    hasAudioTrack () {
      return this.localTracks.some(track => track.kind === 'audio')
    }
  }
}
</script>

<style>
</style>
