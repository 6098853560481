<template>
  <div v-if="audioTrack && audioTrack.kind === 'audio'">
    <AudioTrack :track="audioTrack" />
  </div>
</template>

<script>
import AudioTrack from './AudioTrack.vue'
import { useTracks } from '../../../mixins/VideoCore/useTracks'

export default {
  name: 'ParticipantAudio',
  components: { AudioTrack },
  props: {
    participant: {
      type: Object,
      required: true
    }
  },
  mixins: [useTracks('participant')],
  data () {
    return {
      audioTrack: null
    }
  },
  watch: {
    tracks: {
      handler (data) {
        this.getAudioTrack(data)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getAudioTrack (data) {
      if (!this.tracks) return
      const audioData = data || this.tracks

      this.audioTrack = audioData?.find(track => track.kind === 'audio')
    }
  }
}
</script>

<style>
</style>
