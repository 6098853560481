<template>
  <div v-if="track && track.kind === 'video'">
    <VideoTrack
      :track="track"
      :isLocal="!track.name.includes('screen') && isLocalParticipant"
      :priority="videoPriority"
    />
  </div>
</template>

<script>
import VideoTrack from './VideoTrack.vue'
import { useTrack } from '../../../mixins/VideoCore/useTrack'

export default {
  name: 'Publication',
  props: {
    publication: Object,
    participant: Object,
    videoOnly: Boolean,
    videoPriority: String || null,
    isLocalParticipant: Boolean
  },
  components: { VideoTrack },
  mixins: [useTrack('publication')]
}
</script>

<style>
</style>
